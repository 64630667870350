html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    user-select: none;
    -webkit-touch-callout: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-family: 'Montserrat', 'Trebuchet MS', 'Helvetica', sans-serif;
    color: #1f0409;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
button, input {
    font-family: 'Montserrat', 'Trebuchet MS', 'Helvetica', sans-serif;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

div {
    box-sizing: border-box;
}

/* Fonts */
@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}

.video-visible {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
    opacity: 1 !important;
}

.video-hidden {
    display: none;
}

.item-hidden {
    display: none !important;
}

a,
span,
div {
    -webkit-tap-highlight-color: transparent;
}
.container {
    position: relative;
}
.my-node {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.my-node-enter,
.my-node-appear {
    opacity: 0;
    transform: translateX(10%);
}
.my-node-enter-active,
.my-node-appear-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
}

.my-node-exit {
    opacity: 1;
    transform: translateX(0);
}
.my-node-exit-active {
    opacity: 0;
    transform: translateX(-10%);
    transition: opacity 0.4s ease-out, transform 0.4s ease-out;
}
